import React from 'react'

import Contact2 from '../contact/Contact2'
import Menus from '../menus/Menus';




const MHSection = () => {
  return (
    <>

<Menus />
<Contact2 />

    </>

  )
}

export default MHSection
